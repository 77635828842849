import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'components/ReflexBox';
import useSvg from 'hooks/useSvg';

const IconWrapper = styled(Box)`
  flex-shrink: 0;

  svg {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    path,
    circle,
    rect,
    line,
    polyline {
      stroke: ${(p) =>
        p.colorizeStroke
          ? p.theme.colors[p.strokeColor] || p.theme.colors.text
          : 'none'};
    }

    path.nostroke,
    circle.nostroke,
    rect.nostroke,
    line.nostroke,
    polyline.nostroke {
      stroke: none;
      fill: ${(p) =>
        p.colorizeStroke
          ? p.theme.colors[p.strokeColor] || p.theme.colors.text
          : 'none'};
    }
  }
`;

export default ({
  name = 'datavis',
  noCSS = false,
  colorizeStroke = false,
  strokeColor = null,
  size = 28,
  ...restProps
}) => {
  const IconComponent = useSvg(name);

  if (!IconComponent) {
    return null;
  }

  const WrapperComponent = noCSS ? Box : IconWrapper;

  return (
    <WrapperComponent
      className="icon"
      colorizeStroke={colorizeStroke}
      strokeColor={strokeColor}
      width={size}
      {...restProps}
    >
      <IconComponent width={size} height={size} />
    </WrapperComponent>
  );
};
