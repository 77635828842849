import React from 'react';

export default () => {
  return (
    <iframe
      src="https://player.vimeo.com/video/141403817"
      width="500"
      height="704"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      title="vimeo"
    />
  );
};
