import React, { useState } from 'react';
import { Flex, Box } from 'components/ReflexBox';
import styled from '@emotion/styled';

import Button from 'components/Button';

import { getThemeColor } from 'utils/css-utils';

const ApiUrl = `https://webkid.io/.netlify/functions/newsletter-signup`;

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.violetLighten15};
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
`;

const Title = styled.div`
  font-size: 28px;
  color: white;
  font-weight: 700;
`;

const Subtitle = styled.div`
  color: ${(p) => p.theme.colors.silverDarken30};
  line-height: 1.2;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  border-radius: 25px;
  padding: 12px 20px;
  outline: none;
  background: white;
  box-shadow: none;
  border: 1px solid white;
  color: ${(p) => p.theme.colors.text};
  margin-right: 10px;
  &::placeholder {
    color: ${getThemeColor('textLight')};
  }
`;

const FormWrapper = styled(Box)`
  margin: 0 auto;
`;

const Confirmation = styled(Box)`
  color: white;
`;

const SendButton = styled(Button)`
  width: 175px;
  background: ${(p) => p.theme.colors.red};
  color: white;

  &:hover {
    color: ${(p) => p.theme.colors.red};
    background: white;
  }
`;

function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleInput = (evt) => setEmail(evt.target.value);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (email.length < 1) {
      return;
    }

    setLoading(true);

    const subscribe = async () => {
      try {
        const response = await fetch(`${ApiUrl}?email=${email}`);
        const json = await response.json();

        setResponse(json);
      } catch (err) {
        setResponse({ error: true });
      }
      setLoading(false);
    };

    subscribe();
  };

  const isError =
    response && response.error && response.error !== 'E-mail already exists.';
  const isSuccessful = response && (response.message || !isError);
  const buttonLabel = isLoading ? 'loading...' : 'Send';

  if (isSuccessful) {
    return (
      <Wrapper>
        <Title>Request Alpha Access</Title>
        <Subtitle>
          datablocks is currently in private alpha. You can enter your email
          here to get access and optionally subscribe to our newsletter.
        </Subtitle>
        <Confirmation type="success">
          <span>
            Thanks for your interest in datablocks. Please check your inbox.
          </span>
        </Confirmation>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>Request Alpha Access</Title>
      <Subtitle>
        datablocks is currently in private alpha. You can enter your email here
        to get access and optionally subscribe to our newsletter.
      </Subtitle>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <Flex>
            <InputField
              value={email}
              onChange={handleInput}
              placeholder="Your email"
              type="email"
              minLength={1}
            />
            <SendButton disabled={isLoading} type="big" color="red">
              {buttonLabel}
            </SendButton>
          </Flex>
        </form>
        {isError && (
          <Confirmation type="error">
            <span>
              Something went wrong. You can try to contact us{' '}
              <a href="https://webkid.io/contact">here</a>.
            </span>
          </Confirmation>
        )}
      </FormWrapper>
    </Wrapper>
  );
}

export default NewsletterSignup;
