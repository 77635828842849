import Mail from 'assets/icons/mail.svg';
import Datavis from 'assets/icons/datavis.svg';
import Maps from 'assets/icons/maps.svg';
import Tools from 'assets/icons/tools.svg';
import DataProcessing from 'assets/icons/dataprocessing.svg';
import Graphbar from 'assets/icons/graph_bar.svg';
import ArrowRight from 'assets/icons/arrow_right.svg';
import Menu from 'assets/icons/menu.svg';
import Trophy from 'assets/icons/trophy.svg';
import Adaptive from 'assets/icons/adaptive.svg';
import Open from 'assets/icons/open.svg';
import Precise from 'assets/icons/precise.svg';
import Efficient from 'assets/icons/efficient.svg';

import ZeitOnline from 'assets/clients/zeit_online.svg';
import Taz from 'assets/clients/taz.svg';
import FT from 'assets/clients/financial_times.svg';
import Funke from 'assets/clients/funke.svg';
// import Okf from 'assets/clients/okf.svg';
// import Correctiv from 'assets/clients/correctiv.svg';
// import OECD from 'assets/clients/oecd.svg';
// import FixMyCity from 'assets/clients/fixmycity.svg';
// import SRF from 'assets/clients/srf.svg';
// import DB from 'assets/clients/db.svg';
// import NZZ from 'assets/clients/nzz.svg';
// import BrandEins from 'assets/clients/brand_eins.svg';

const preLoaded = {
  mail: Mail,
  datavis: Datavis,
  maps: Maps,
  tools: Tools,
  dataprocessing: DataProcessing,
  graph_bar: Graphbar,
  arrow_right: ArrowRight,
  menu: Menu,
  trophy: Trophy,
  adaptive: Adaptive,
  open: Open,
  precise: Precise,
  efficient: Efficient,
  zeit_online: ZeitOnline,
  taz: Taz,
  financial_times: FT,
  funke: Funke,
};

export const getPreLoadedIcon = (name) =>
  preLoaded[name] ? () => preLoaded[name] : null;
