import { useState, useEffect } from 'react';
import { getPreLoadedIcon } from 'utils/svg-preloader';

export function useSvg(name, options = {}) {
  const { path = 'icons' } = options;
  const [svg, setSvg] = useState(getPreLoadedIcon(name));

  useEffect(() => {
    if (!svg) {
      (async () => {
        try {
          const svgComp = await import(`assets/${path}/${name}.svg`);
          setSvg(svgComp);
        } catch (err) {
          console.warn(`error loading icon: ${name}`);
        }
      })();
    }
  }, [svg, name]);

  if (!svg) {
    return null;
  }

  return svg.default ? svg.default : svg;
}

export default useSvg;
