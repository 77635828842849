import React, { memo } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'components/ReflexBox';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { H2, H4, Label } from 'components/Typo';
import Button from 'components/Button';
import { getThemeSpacePx, device } from 'utils/css-utils';

const AdWrapper = styled(Box)`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    350deg,
    ${(p) => p.theme.colors.silverLighten60} 0%,
    ${(p) => p.theme.colors.silverLighten60} 50%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  max-width: 100% !important;
`;

const StyledImg = styled(GatsbyImage)`
  box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.16);
`;

const Centered = styled(Box)`
  max-width: ${(p) => p.theme.maxWidth};
  margin: 0 auto;
`;

const ContactLink = styled(Link)`
  margin-right: ${getThemeSpacePx(3)};
`;

const ImageCaption = styled(Flex)`
  display: none;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    margin-top: ${getThemeSpacePx(1)};
  }
`;

const BlogpostAd = memo(
  ({
    kicker = 'Are you interested in a collaboration?',
    title = 'We are specialized in creating custom data visualizations and web-based tools.',
  }) => {
    const data = useStaticQuery(graphql`
      query {
        file(
          sourceInstanceName: { eq: "assets" }
          relativePath: { eq: "images/blog-webkid-teaser.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `);

    return (
      <AdWrapper pt={5} pb={6}>
        <Centered>
          <Flex alignItems="flex-end" px={[0, 0, -3]} flexWrap="wrap">
            <Box
              width={[1, 1, 1 / 2]}
              px={[0, 0, 3]}
              style={{ position: 'relative' }}
              mb={[2, 2, 0]}
            >
              <StyledImg image={data.file.childImageSharp.gatsbyImageData} />
              <ImageCaption>
                <Label fontWeight={700}>Colors Of Europe</Label>
                <Label>Interactive Data Visualization (Zeit Online)</Label>
              </ImageCaption>
            </Box>
            <Box width={[1, 1, 1 / 2]} px={[0, 0, 3]}>
              <H4 style={{ fontWeight: 400 }} as="div">
                {kicker}
              </H4>
              <H2 as="div">{title}</H2>
              <Flex mt={[3, 3, 4]}>
                <ContactLink to="/contact">
                  <Button icon="mail">Contact us</Button>
                </ContactLink>
                <Button
                  as={Link}
                  to="/services"
                  type="ghost"
                  icon="info_circle"
                >
                  Learn more
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Centered>
      </AdWrapper>
    );
  }
);

export default BlogpostAd;
